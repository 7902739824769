import React from 'react';
import { Container, Box, Divider } from '@mui/material';
import NeedsEvaluator from '../components/NeedsEvaluator';
import UsefulTools from '../components/UsefulTools';
import SpeedTest from '../components/SpeedTest';

const Tools = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <SpeedTest />
        <Divider sx={{ my: 6 }} />
        <NeedsEvaluator />
        <Divider sx={{ my: 6 }} />
        <UsefulTools />
      </Box>
    </Container>
  );
};

export default Tools;
