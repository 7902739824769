import React from 'react';
import { Box, Typography } from '@mui/material';
import { Language } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: 'inherit',
  textDecoration: 'none',
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
    animation: 'rotate 20s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '1.5rem',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textShadow: '1px 1px 1px rgba(0,0,0,0.1)',
}));

const Logo = ({ variant = 'default' }) => {
  return (
    <LogoWrapper>
      <Language sx={{ 
        color: variant === 'white' ? 'white' : 'primary.main',
      }} />
      <LogoText
        variant="h6"
        sx={{
          background: variant === 'white' 
            ? 'linear-gradient(45deg, #ffffff, #e0e0e0)'
            : undefined,
        }}
      >
        Top Internet
      </LogoText>
    </LogoWrapper>
  );
};

export default Logo;
