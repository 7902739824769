import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { CompareArrows, Star, Build } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    fontSize: '3rem',
    color: theme.palette.primary.main,
  },
}));

const features = [
  {
    icon: <CompareArrows />,
    title: 'Comparaison des Fournisseurs',
    description: 'Comparez facilement les différentes offres des fournisseurs d\'accès internet pour trouver celle qui vous correspond le mieux.',
  },
  {
    icon: <Star />,
    title: 'Avis des Utilisateurs',
    description: 'Découvrez les retours d\'expérience et les notes attribuées par de vrais utilisateurs aux différents services internet.',
  },
  {
    icon: <Build />,
    title: 'Outils en Ligne',
    description: 'Utilisez nos outils interactifs pour évaluer vos besoins en internet et identifier l\'offre la plus adaptée à votre situation.',
  },
];

const FeatureBlocks = () => {
  return (
    <Box sx={{ py: 8, px: 2 }}>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <FeatureCard elevation={3}>
              <IconWrapper>
                {feature.icon}
              </IconWrapper>
              <Typography variant="h5" component="h3" gutterBottom>
                {feature.title}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {feature.description}
              </Typography>
            </FeatureCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeatureBlocks;
