import React from 'react';
import { Box, Container, Divider } from '@mui/material';
import Hero from '../components/Hero';
import FeatureBlocks from '../components/FeatureBlocks';
import InteractiveComparison from '../components/InteractiveComparison';
import ContactForm from '../components/ContactForm';
import AffiliateBlocks from '../components/AffiliateBlocks';
import InfoStats from '../components/InfoStats';
import PracticalTips from '../components/PracticalTips';

const Home = () => {
  return (
    <Box>
      <Hero />
      <Container maxWidth="lg">
        <InfoStats />
        <Divider sx={{ my: 4 }} />
        <FeatureBlocks />
        <Divider sx={{ my: 4 }} />
        <InteractiveComparison />
        <Divider sx={{ my: 4 }} />
        <PracticalTips />
        <Divider sx={{ my: 4 }} />
        <AffiliateBlocks />
        <Divider sx={{ my: 4 }} />
        <ContactForm />
      </Container>
    </Box>
  );
};

export default Home;
