import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
} from '@mui/material';
import {
  Speed,
  Security,
  SaveAlt,
  Router,
  AttachMoney,
  Build,
} from '@mui/icons-material';

const tips = [
  {
    title: "Optimiser votre connexion",
    icon: Speed,
    items: [
      "Placez votre box dans un endroit dégagé et central",
      "Utilisez un câble Ethernet pour les appareils fixes",
      "Activez la double bande (2.4GHz et 5GHz)",
      "Mettez à jour régulièrement votre box"
    ]
  },
  {
    title: "Sécuriser votre réseau",
    icon: Security,
    items: [
      "Changez le mot de passe par défaut de votre box",
      "Activez le WPA3 si disponible",
      "Désactivez le WPS si non utilisé",
      "Créez un réseau invité distinct"
    ]
  },
  {
    title: "Réduire votre consommation",
    icon: SaveAlt,
    items: [
      "Utilisez des DNS rapides (Cloudflare, Google)",
      "Installez un bloqueur de publicités",
      "Activez la compression des données",
      "Surveillez votre consommation mensuelle"
    ]
  },
  {
    title: "Choisir son équipement",
    icon: Router,
    items: [
      "Vérifiez la compatibilité WiFi 6",
      "Optez pour des répéteurs mesh si nécessaire",
      "Privilégiez les appareils certifiés",
      "Investissez dans une bonne carte réseau"
    ]
  },
  {
    title: "Économiser sur son abonnement",
    icon: AttachMoney,
    items: [
      "Comparez les offres régulièrement",
      "Négociez avec votre opérateur",
      "Profitez des offres de parrainage",
      "Évitez les options inutiles"
    ]
  },
  {
    title: "Dépanner efficacement",
    icon: Build,
    items: [
      "Redémarrez la box en cas de problème",
      "Testez votre débit régulièrement",
      "Gardez les logs de connexion",
      "Contactez le support au bon moment"
    ]
  }
];

const PracticalTips = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Conseils Pratiques
      </Typography>

      <Grid container spacing={3}>
        {tips.map((tip, index) => {
          const IconComponent = tip.icon;
          return (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <IconComponent
                      sx={{
                        fontSize: 40,
                        color: theme.palette.primary.main,
                        mr: 2,
                      }}
                    />
                    <Typography variant="h5" component="div">
                      {tip.title}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <List dense>
                    {tip.items.map((item, itemIndex) => (
                      <ListItem key={itemIndex}>
                        <ListItemIcon
                          sx={{
                            minWidth: 32,
                            color: theme.palette.primary.main,
                          }}
                        >
                          •
                        </ListItemIcon>
                        <ListItemText
                          primary={item}
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PracticalTips;
