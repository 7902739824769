import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';

const mockData = [
  {
    provider: 'Orange',
    speed: 1000,
    price: 39.99,
    contract: 12,
    rating: 4.5,
  },
  {
    provider: 'SFR',
    speed: 500,
    price: 32.99,
    contract: 12,
    rating: 4.0,
  },
  {
    provider: 'Free',
    speed: 1000,
    price: 29.99,
    contract: 12,
    rating: 4.2,
  },
  {
    provider: 'Bouygues',
    speed: 1000,
    price: 35.99,
    contract: 12,
    rating: 4.3,
  },
];

const InteractiveComparison = () => {
  const [filters, setFilters] = useState({
    minSpeed: '',
    maxPrice: '',
    sortBy: 'price',
  });

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const filteredData = mockData
    .filter((item) => {
      if (filters.minSpeed && item.speed < filters.minSpeed) return false;
      if (filters.maxPrice && item.price > filters.maxPrice) return false;
      return true;
    })
    .sort((a, b) => {
      if (filters.sortBy === 'price') return a.price - b.price;
      if (filters.sortBy === 'speed') return b.speed - a.speed;
      if (filters.sortBy === 'rating') return b.rating - a.rating;
      return 0;
    });

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <TextField
          name="minSpeed"
          label="Vitesse min (Mb/s)"
          type="number"
          value={filters.minSpeed}
          onChange={handleFilterChange}
        />
        <TextField
          name="maxPrice"
          label="Prix max (€)"
          type="number"
          value={filters.maxPrice}
          onChange={handleFilterChange}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Trier par</InputLabel>
          <Select
            name="sortBy"
            value={filters.sortBy}
            onChange={handleFilterChange}
            label="Trier par"
          >
            <MenuItem value="price">Prix</MenuItem>
            <MenuItem value="speed">Vitesse</MenuItem>
            <MenuItem value="rating">Note</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fournisseur</TableCell>
              <TableCell>Vitesse (Mb/s)</TableCell>
              <TableCell>Prix (€/mois)</TableCell>
              <TableCell>Engagement (mois)</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.provider}</TableCell>
                <TableCell>{row.speed}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.contract}</TableCell>
                <TableCell>{row.rating}/5</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InteractiveComparison;
