import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Grid,
  Rating,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  CircularProgress,
  Snackbar,
  Alert,
  Chip,
  Tooltip,
  Divider,
  Collapse,
  IconButton,
  Fade,
  Grow,
  Zoom,
} from '@mui/material';
import {
  Check,
  ContentCopy,
  ExpandMore,
  ExpandLess,
  Launch,
  LocalOffer,
  CheckCircleOutline,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  Speed as SpeedIcon,
  Support as SupportIcon,
  Euro as EuroIcon,
  Router,
  Security,
  Shield,
  Cloud,
  Work,
} from '@mui/icons-material';

const ICONS = {
  Router,
  Security,
  Shield,
  Cloud,
  Work,
};

const AffiliateBlocks = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [activeTab, setActiveTab] = useState('features');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const response = await fetch('/data/categories.json');
        const data = await response.json();
        setCategories(data.categories);
        if (data.categories.length > 0) {
          loadCategoryData(data.categories[0].id);
        }
      } catch (err) {
        setError('Erreur lors du chargement des catégories');
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  const loadCategoryData = async (categoryId) => {
    setLoading(true);
    try {
      const response = await fetch(`/data/${categoryId}.json`);
      const data = await response.json();
      setCategoryData(data);
    } catch (err) {
      setError('Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
    if (categories[newValue]) {
      loadCategoryData(categories[newValue].id);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4 }}>
        {categoryData?.emoji} Outils et Services Recommandés
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs
          value={selectedCategory}
          onChange={handleCategoryChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {categories.map((cat, index) => {
            const Icon = ICONS[cat.icon];
            return (
              <Tab
                key={cat.id}
                icon={Icon ? <Icon /> : null}
                label={cat.name}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
              />
            );
          })}
        </Tabs>
      </Box>

      {categoryData && categoryData.items && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {categoryData.items.map((item) => (
              <Grid item xs={12} md={6} lg={4} key={item.id}>
                <Grow in timeout={500}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: (theme) => `0 8px 24px ${theme.palette.primary.main}20`
                      }
                    }}
                  >
                    {item.promoCode && (
                      <Zoom in>
                        <Chip
                          label="TOP CHOIX"
                          color="primary"
                          size="small"
                          sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            zIndex: 1,
                            fontWeight: 'bold'
                          }}
                        />
                      </Zoom>
                    )}

                    <CardMedia
                      component="img"
                      height="140"
                      image={item.image}
                      alt={item.name}
                      sx={{ objectFit: 'contain', p: 2, bgcolor: 'background.paper' }}
                    />

                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h5" gutterBottom>
                        {item.name}
                      </Typography>
                      
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        {item.description}
                      </Typography>

                      <Box sx={{ my: 2 }}>
                        <Tabs 
                          value={activeTab} 
                          onChange={(e, newValue) => setActiveTab(newValue)}
                          variant="scrollable"
                          scrollButtons="auto"
                          sx={{ mb: 2 }}
                        >
                          <Tab label="Fonctionnalités" value="features" />
                          <Tab label="Avantages" value="advantages" />
                          <Tab label="Prix" value="price" />
                          <Tab label="Plus" value="more" />
                        </Tabs>

                        <TabPanel value={activeTab} index="features">
                          {item.features && (
                            <List dense>
                              {item.features.map((feature, index) => (
                                <ListItem key={index}>
                                  <ListItemIcon>
                                    <CheckCircleOutline color="primary" />
                                  </ListItemIcon>
                                  <ListItemText primary={feature} />
                                </ListItem>
                              ))}
                            </List>
                          )}
                        </TabPanel>

                        <TabPanel value={activeTab} index="advantages">
                          <Grid container spacing={1}>
                            {item.advantages && (
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                  ✅ Avantages
                                </Typography>
                                {item.advantages.map((adv, index) => (
                                  <Chip
                                    key={index}
                                    label={adv}
                                    size="small"
                                    color="success"
                                    variant="outlined"
                                    sx={{ m: 0.5 }}
                                  />
                                ))}
                              </Grid>
                            )}
                            {item.disadvantages && (
                              <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                  ⚠️ Points d'attention
                                </Typography>
                                {item.disadvantages.map((dis, index) => (
                                  <Chip
                                    key={index}
                                    label={dis}
                                    size="small"
                                    color="warning"
                                    variant="outlined"
                                    sx={{ m: 0.5 }}
                                  />
                                ))}
                              </Grid>
                            )}
                          </Grid>
                        </TabPanel>

                        <TabPanel value={activeTab} index="price">
                          <Box>
                            <Typography variant="h6" gutterBottom>
                              À partir de {item.price}
                            </Typography>
                            {item.priceDetails && (
                              <Collapse in={showPriceDetails}>
                                <List dense>
                                  {Object.entries(item.priceDetails).map(([key, value]) => (
                                    <ListItem key={key}>
                                      <ListItemText 
                                        primary={value}
                                        secondary={key.charAt(0).toUpperCase() + key.slice(1)}
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            )}
                            <Button
                              size="small"
                              onClick={() => setShowPriceDetails(!showPriceDetails)}
                              endIcon={showPriceDetails ? <ExpandLess /> : <ExpandMore />}
                            >
                              {showPriceDetails ? 'Moins de détails' : 'Plus de détails'}
                            </Button>
                          </Box>
                        </TabPanel>

                        <TabPanel value={activeTab} index="more">
                          <Grid container spacing={2}>
                            {item.ratings && (
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                  📊 Évaluations détaillées
                                </Typography>
                                {Object.entries(item.ratings).map(([key, value]) => (
                                  <Box key={key} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Typography variant="body2" sx={{ minWidth: 100 }}>
                                      {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </Typography>
                                    <Rating value={value} precision={0.1} size="small" readOnly />
                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                      {value}
                                    </Typography>
                                  </Box>
                                ))}
                              </Grid>
                            )}
                            
                            {item.compatibility && (
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                  🔌 Compatibilité
                                </Typography>
                                <List dense>
                                  {Object.entries(item.compatibility).map(([platform, devices]) => (
                                    <ListItem key={platform}>
                                      <ListItemText
                                        primary={
                                          <Typography variant="body2">
                                            {platform === 'os' ? '💻' :
                                             platform === 'browsers' ? '🌐' :
                                             platform === 'mobile' ? '📱' : '⚙️'} {' '}
                                            {Array.isArray(devices) ? devices.join(', ') : devices}
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Grid>
                            )}
                          </Grid>
                        </TabPanel>
                      </Box>
                    </CardContent>

                    <CardActions sx={{ p: 2, pt: 0 }}>
                      <Button 
                        variant="contained" 
                        fullWidth 
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        startIcon={<Launch />}
                      >
                        Voir l'offre
                      </Button>
                    </CardActions>

                    {item.promoCode && (
                      <Box sx={{ p: 2, pt: 0 }}>
                        <Alert severity="success" icon={<LocalOffer />}>
                          Code promo: <strong>{item.promoCode}</strong>
                        </Alert>
                      </Box>
                    )}
                  </Card>
                </Grow>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Container>
  );
};

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>
    {value === index && <Box>{children}</Box>}
  </div>
);

export default AffiliateBlocks;
