import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Rating,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from '@mui/material';
import {
  Extension,
  Language,
  Speed,
  Security,
  Build,
  Launch,
  Check,
} from '@mui/icons-material';

const UsefulTools = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const [toolsData, setToolsData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/data/tools.json')
      .then(response => response.json())
      .then(data => {
        setToolsData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error loading tools data:', error);
        setLoading(false);
      });
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'Sécurité':
        return Security;
      case 'Performance':
        return Speed;
      case 'Shopping':
      case 'Test Débit':
      case 'Diagnostic':
        return Build;
      default:
        return Build;
    }
  };

  const renderToolCard = (tool, isExtension) => {
    const IconComponent = getCategoryIcon(tool.category);
    
    return (
      <Grid item xs={12} sm={6} md={4} key={tool.name}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'translateY(-4px)',
            },
          }}
        >
          <CardMedia
            component="img"
            height="140"
            image={tool.image}
            alt={tool.name}
            sx={{ objectFit: 'contain', p: 2, bgcolor: 'grey.100' }}
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <IconComponent sx={{ color: theme.palette.primary.main, mr: 1 }} />
              <Typography variant="h6" component="h3">
                {tool.name}
              </Typography>
            </Box>
            
            <Chip
              label={tool.category}
              size="small"
              sx={{ mb: 1 }}
              color="primary"
            />
            
            <Typography variant="body2" color="text.secondary" paragraph>
              {tool.description}
            </Typography>
            
            <List dense>
              {tool.features.map((feature, index) => (
                <ListItem key={index} disableGutters>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <Check sx={{ color: theme.palette.success.main }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={feature}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'text.secondary',
                    }}
                  />
                </ListItem>
              ))}
            </List>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 2 }}>
              <Rating value={tool.rating} precision={0.1} readOnly size="small" />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                {tool.rating}
              </Typography>
            </Box>
            
            <Typography variant="caption" color="text.secondary" display="block">
              {isExtension ? 'Utilisateurs' : 'Visites'}: {isExtension ? tool.users : tool.visits}
            </Typography>
            
            <Button
              variant="contained"
              color="primary"
              endIcon={<Launch />}
              fullWidth
              href={tool.link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mt: 2 }}
            >
              {isExtension ? 'Installer' : 'Visiter'}
            </Button>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Outils Recommandés
        </Typography>
        <Grid container spacing={3}>
          {[1, 2, 3].map(i => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Skeleton variant="rectangular" height={400} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Outils Recommandés
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab
            icon={<Extension />}
            label="Extensions Chrome"
            iconPosition="start"
          />
          <Tab
            icon={<Language />}
            label="Sites Web Utiles"
            iconPosition="start"
          />
        </Tabs>
      </Box>

      <Grid container spacing={3}>
        {toolsData && currentTab === 0
          ? toolsData.chromeExtensions.map(ext => renderToolCard(ext, true))
          : toolsData && toolsData.usefulWebsites.map(site => renderToolCard(site, false))}
      </Grid>
    </Container>
  );
};

export default UsefulTools;
