import React from 'react';
import { Box, Container, Grid, Card, CardContent, Typography, useTheme } from '@mui/material';
import { Speed, Router, Euro, People } from '@mui/icons-material';

const stats = [
  {
    title: "Débit Moyen en France",
    value: "183 Mb/s",
    description: "Vitesse moyenne de téléchargement en 2024",
    icon: Speed,
    color: "#2196F3"
  },
  {
    title: "Couverture Fibre",
    value: "81%",
    description: "Des foyers français éligibles à la fibre optique",
    icon: Router,
    color: "#4CAF50"
  },
  {
    title: "Budget Internet",
    value: "33€/mois",
    description: "Dépense moyenne par foyer pour internet",
    icon: Euro,
    color: "#FF9800"
  },
  {
    title: "Satisfaction Client",
    value: "73%",
    description: "Taux de satisfaction moyen des utilisateurs",
    icon: People,
    color: "#9C27B0"
  }
];

const InfoStats = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Internet en France
      </Typography>
      
      <Grid container spacing={3}>
        {stats.map((stat, index) => {
          const IconComponent = stat.icon;
          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                    }}
                  >
                    <IconComponent
                      sx={{
                        fontSize: 40,
                        color: stat.color,
                        mr: 1,
                      }}
                    />
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontWeight: 'bold',
                        color: stat.color,
                      }}
                    >
                      {stat.value}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    component="div"
                    gutterBottom
                    sx={{ fontWeight: 'medium' }}
                  >
                    {stat.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {stat.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default InfoStats;
