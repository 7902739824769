import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeroContainer = styled(Box)({
  height: '70vh',
  position: 'relative',
  overflow: 'hidden',
});

const ContentOverlay = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  zIndex: 1,
  color: 'white',
});

const NetworkNode = ({ position }) => {
  return (
    <mesh position={position}>
      <sphereGeometry args={[0.3, 32, 32]} />
      <meshStandardMaterial color="#4CAF50" />
    </mesh>
  );
};

const NetworkConnection = ({ start, end }) => {
  const points = [start, end];
  return (
    <line>
      <bufferGeometry attach="geometry" />
      <lineBasicMaterial attach="material" color="#4CAF50" linewidth={1} />
    </line>
  );
};

const NetworkVisualization = () => {
  const nodes = [
    [-2, 0, 0],
    [2, 0, 0],
    [0, 2, 0],
    [0, -2, 0],
    [0, 0, 2],
  ];

  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      {nodes.map((pos, idx) => (
        <NetworkNode key={idx} position={pos} />
      ))}
      <OrbitControls enableZoom={false} autoRotate />
    </>
  );
};

const Hero = () => {
  return (
    <HeroContainer>
      <Canvas>
        <NetworkVisualization />
      </Canvas>
      <ContentOverlay>
        <Typography variant="h2" gutterBottom>
          Top Internet
        </Typography>
        <Typography variant="h5" gutterBottom>
          Votre Guide Ultime des Services Internet
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/comparaison"
          sx={{ mt: 4 }}
        >
          Comparez les Offres
        </Button>
      </ContentOverlay>
    </HeroContainer>
  );
};

export default Hero;
