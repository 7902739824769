import React, { useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { ExpandMore, Speed, Router, Euro, Build } from '@mui/icons-material';

const guides = {
  debutant: [
    {
      title: "Comment choisir son forfait internet ?",
      content: `
        1. Évaluez vos besoins en débit
        2. Comparez les technologies disponibles (ADSL, Fibre)
        3. Vérifiez la couverture dans votre zone
        4. Examinez les options et services inclus
        5. Comparez les prix et engagements
      `,
      image: "/images/guides/choosing-internet.jpg",
      icon: <Speed />,
    },
    {
      title: "Les différentes technologies internet",
      content: `
        • ADSL : Connexion via ligne téléphonique
        • VDSL : Version améliorée de l'ADSL
        • Fibre optique : La plus rapide et stable
        • 4G/5G : Internet mobile haute vitesse
      `,
      image: "/images/guides/technologies.jpg",
      icon: <Router />,
    },
  ],
  expert: [
    {
      title: "Optimiser sa connexion internet",
      content: `
        1. Positionnement optimal du routeur
        2. Configuration des canaux WiFi
        3. Utilisation d'un répéteur WiFi
        4. Mise à jour du firmware
        5. QoS (Quality of Service)
      `,
      image: "/images/guides/optimization.jpg",
      icon: <Build />,
    },
    {
      title: "Réduire sa facture internet",
      content: `
        • Négocier avec son opérateur
        • Profiter des offres promotionnelles
        • Comparer régulièrement les offres
        • Adapter son forfait à ses besoins
        • Combiner les services (box + mobile)
      `,
      image: "/images/guides/cost-reduction.jpg",
      icon: <Euro />,
    },
  ],
};

const faq = [
  {
    question: "Quelle est la différence entre l'ADSL et la fibre ?",
    answer: "L'ADSL utilise le réseau téléphonique classique avec des débits jusqu'à 20 Mb/s, tandis que la fibre optique utilise des câbles en fibre de verre permettant des débits jusqu'à 10 Gb/s. La fibre est plus stable et plus rapide, mais n'est pas disponible partout.",
  },
  {
    question: "Ai-je besoin de la fibre optique ?",
    answer: "Cela dépend de vos usages. La fibre est recommandée si vous : streamez en 4K, téléchargez fréquemment de gros fichiers, jouez en ligne, travaillez à distance, ou si plusieurs personnes utilisent internet simultanément.",
  },
  {
    question: "Comment vérifier l'éligibilité à la fibre ?",
    answer: "Vous pouvez vérifier votre éligibilité sur les sites des opérateurs ou sur le site gouvernemental cartefibre.arcep.fr. Il suffit d'entrer votre adresse pour connaître les technologies disponibles.",
  },
];

const Guides = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Guides et Conseils
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Guides Débutants" />
          <Tab label="Guides Experts" />
          <Tab label="FAQ" />
        </Tabs>
      </Box>

      {currentTab === 0 && (
        <Grid container spacing={4}>
          {guides.debutant.map((guide, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <CardMedia
                  component="div"
                  sx={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'primary.light',
                  }}
                >
                  {guide.icon}
                </CardMedia>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {guide.title}
                  </Typography>
                  <Typography variant="body1" component="div">
                    {guide.content.split('\n').map((line, i) => (
                      <Box key={i} sx={{ mb: 1 }}>
                        {line}
                      </Box>
                    ))}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    href={`/guide/${guide.title.toLowerCase().replace(/\s+/g, '-')}`}
                  >
                    Lire le guide complet
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {currentTab === 1 && (
        <Grid container spacing={4}>
          {guides.expert.map((guide, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <CardMedia
                  component="div"
                  sx={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'secondary.light',
                  }}
                >
                  {guide.icon}
                </CardMedia>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {guide.title}
                  </Typography>
                  <Typography variant="body1" component="div">
                    {guide.content.split('\n').map((line, i) => (
                      <Box key={i} sx={{ mb: 1 }}>
                        {line}
                      </Box>
                    ))}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 2 }}
                    href={`/guide/${guide.title.toLowerCase().replace(/\s+/g, '-')}`}
                  >
                    Lire le guide complet
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {currentTab === 2 && (
        <Box>
          {faq.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="subtitle1">{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default Guides;
