import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Rating,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { ThumbUp, ThumbDown, VerifiedUser } from '@mui/icons-material';

const mockReviews = [
  {
    id: 1,
    author: 'Jean D.',
    provider: 'Orange',
    rating: 4.5,
    date: '2023-12-20',
    content: 'Très satisfait de ma fibre Orange. Installation rapide et service client efficace.',
    likes: 12,
    dislikes: 2,
    verified: true,
  },
  {
    id: 2,
    author: 'Marie L.',
    provider: 'SFR',
    rating: 3.5,
    date: '2023-12-18',
    content: 'Connection stable mais le service client pourrait être meilleur.',
    likes: 8,
    dislikes: 3,
    verified: true,
  },
  // Ajoutez plus d'avis ici
];

const Reviews = () => {
  const [reviews, setReviews] = useState(mockReviews);
  const [openDialog, setOpenDialog] = useState(false);
  const [newReview, setNewReview] = useState({
    provider: '',
    rating: 0,
    content: '',
  });
  const [filter, setFilter] = useState('all');

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewReview({ provider: '', rating: 0, content: '' });
  };

  const handleSubmitReview = () => {
    const review = {
      id: reviews.length + 1,
      author: 'Utilisateur',
      ...newReview,
      date: new Date().toISOString().split('T')[0],
      likes: 0,
      dislikes: 0,
      verified: false,
    };
    setReviews([review, ...reviews]);
    handleCloseDialog();
  };

  const handleLike = (reviewId, type) => {
    setReviews(reviews.map(review => {
      if (review.id === reviewId) {
        return {
          ...review,
          [type]: review[type] + 1,
        };
      }
      return review;
    }));
  };

  const filteredReviews = filter === 'all' 
    ? reviews 
    : reviews.filter(review => review.provider === filter);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1">
          Avis des Utilisateurs
        </Typography>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Donner mon avis
        </Button>
      </Box>

      <Box sx={{ mb: 4 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filtrer par fournisseur</InputLabel>
          <Select
            value={filter}
            label="Filtrer par fournisseur"
            onChange={(e) => setFilter(e.target.value)}
          >
            <MenuItem value="all">Tous les fournisseurs</MenuItem>
            <MenuItem value="Orange">Orange</MenuItem>
            <MenuItem value="SFR">SFR</MenuItem>
            <MenuItem value="Free">Free</MenuItem>
            <MenuItem value="Bouygues">Bouygues</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        {filteredReviews.map((review) => (
          <Grid item xs={12} key={review.id}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ mr: 2 }}>{review.author[0]}</Avatar>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1" sx={{ mr: 1 }}>
                        {review.author}
                      </Typography>
                      {review.verified && (
                        <Chip
                          icon={<VerifiedUser />}
                          label="Vérifié"
                          size="small"
                          color="primary"
                        />
                      )}
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {review.date}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Chip label={review.provider} sx={{ mr: 1 }} />
                  <Rating value={review.rating} precision={0.5} readOnly />
                </Box>

                <Typography variant="body1" sx={{ mb: 2 }}>
                  {review.content}
                </Typography>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    startIcon={<ThumbUp />}
                    onClick={() => handleLike(review.id, 'likes')}
                  >
                    {review.likes}
                  </Button>
                  <Button
                    startIcon={<ThumbDown />}
                    onClick={() => handleLike(review.id, 'dislikes')}
                  >
                    {review.dislikes}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Donner mon avis</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Fournisseur</InputLabel>
            <Select
              value={newReview.provider}
              label="Fournisseur"
              onChange={(e) => setNewReview({ ...newReview, provider: e.target.value })}
            >
              <MenuItem value="Orange">Orange</MenuItem>
              <MenuItem value="SFR">SFR</MenuItem>
              <MenuItem value="Free">Free</MenuItem>
              <MenuItem value="Bouygues">Bouygues</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ my: 2 }}>
            <Typography component="legend">Note</Typography>
            <Rating
              value={newReview.rating}
              onChange={(event, newValue) => {
                setNewReview({ ...newReview, rating: newValue });
              }}
            />
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Votre avis"
            value={newReview.content}
            onChange={(e) => setNewReview({ ...newReview, content: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button 
            onClick={handleSubmitReview}
            variant="contained"
            disabled={!newReview.provider || !newReview.rating || !newReview.content}
          >
            Publier
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Reviews;
