import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Paper,
  Grid,
  useTheme,
  LinearProgress,
} from '@mui/material';
import { Speed, CloudDownload, CloudUpload } from '@mui/icons-material';

const SpeedTest = () => {
  const theme = useTheme();
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const [latency, setLatency] = useState(null);
  const [testing, setTesting] = useState(false);
  const [progress, setProgress] = useState(0);

  // Configuration du test
  const testConfig = {
    downloadSize: 10 * 1024 * 1024, // 10MB pour le test de download
    uploadSize: 2 * 1024 * 1024, // 2MB pour le test d'upload
    testDuration: 8000, // 8 secondes par test
    updateInterval: 100, // Mise à jour tous les 100ms
    testServer: 'https://speed.cloudflare.com/__down', // Serveur de test Cloudflare
    timeout: 15000, // 15 secondes de timeout
  };

  // Mesure de la latence
  const measureLatency = async () => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), testConfig.timeout);

    try {
      const start = performance.now();
      await fetch(testConfig.testServer, { 
        method: 'HEAD',
        signal: controller.signal 
      });
      const end = performance.now();
      clearTimeout(timeoutId);
      return Math.round(end - start);
    } catch (error) {
      console.error('Erreur lors du test de latence:', error);
      if (error.name === 'AbortError') {
        alert('Le test de latence a pris trop de temps. Veuillez réessayer.');
      } else {
        alert('Erreur lors du test de latence. Veuillez vérifier votre connexion.');
      }
      return null;
    }
  };

  // Test de téléchargement
  const testDownloadSpeed = () => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      const timeoutId = setTimeout(() => {
        xhr.abort();
        console.error('Timeout du test de téléchargement');
        resolve(0);
      }, testConfig.timeout);

      const startTime = performance.now();
      let lastProgressTime = startTime;
      let lastLoadedBytes = 0;
      let speeds = [];

      xhr.open('GET', `${testConfig.testServer}?bytes=${testConfig.downloadSize}`, true);
      
      xhr.onprogress = (event) => {
        const currentTime = performance.now();
        const timeDiff = currentTime - lastProgressTime;
        const loadedDiff = event.loaded - lastLoadedBytes;
        
        if (timeDiff > testConfig.updateInterval) {
          const speed = (loadedDiff * 8) / (timeDiff / 1000); // bits par seconde
          speeds.push(speed / 1024 / 1024); // Conversion en Mbps
          
          lastProgressTime = currentTime;
          lastLoadedBytes = event.loaded;
          
          const totalProgress = (event.loaded / testConfig.downloadSize) * 100;
          setProgress(totalProgress);
        }
      };

      xhr.onload = () => {
        clearTimeout(timeoutId);
        const avgSpeed = speeds.length > 0 
          ? speeds.reduce((a, b) => a + b, 0) / speeds.length 
          : 0;
        resolve(avgSpeed);
      };

      xhr.onerror = () => {
        clearTimeout(timeoutId);
        console.error('Erreur lors du test de téléchargement');
        alert('Erreur lors du test de téléchargement. Veuillez vérifier votre connexion.');
        resolve(0);
      };

      xhr.send();
    });
  };

  // Test d'upload
  const testUploadSpeed = () => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      const timeoutId = setTimeout(() => {
        xhr.abort();
        console.error('Timeout du test d\'upload');
        resolve(0);
      }, testConfig.timeout);

      const data = new ArrayBuffer(testConfig.uploadSize);
      const startTime = performance.now();
      let lastProgressTime = startTime;
      let lastLoadedBytes = 0;
      let speeds = [];

      xhr.open('POST', testConfig.testServer, true);
      xhr.upload.onprogress = (event) => {
        const currentTime = performance.now();
        const timeDiff = currentTime - lastProgressTime;
        const loadedDiff = event.loaded - lastLoadedBytes;
        
        if (timeDiff > testConfig.updateInterval) {
          const speed = (loadedDiff * 8) / (timeDiff / 1000);
          speeds.push(speed / 1024 / 1024);
          
          lastProgressTime = currentTime;
          lastLoadedBytes = event.loaded;
          
          const totalProgress = (event.loaded / testConfig.uploadSize) * 100;
          setProgress(totalProgress);
        }
      };

      xhr.onload = () => {
        clearTimeout(timeoutId);
        const avgSpeed = speeds.length > 0 
          ? speeds.reduce((a, b) => a + b, 0) / speeds.length 
          : 0;
        resolve(avgSpeed);
      };

      xhr.onerror = () => {
        clearTimeout(timeoutId);
        console.error('Erreur lors du test d\'upload');
        alert('Erreur lors du test d\'upload. Veuillez vérifier votre connexion.');
        resolve(0);
      };

      xhr.send(data);
    });
  };

  const startTest = async () => {
    try {
      setTesting(true);
      setProgress(0);
      setDownloadSpeed(null);
      setUploadSpeed(null);
      setLatency(null);

      // Test de latence
      const pingResult = await measureLatency();
      if (pingResult === null) {
        throw new Error('Test de latence échoué');
      }
      setLatency(pingResult);

      // Test de téléchargement
      setProgress(0);
      const downloadResult = await testDownloadSpeed();
      if (downloadResult === 0) {
        throw new Error('Test de téléchargement échoué');
      }
      setDownloadSpeed(downloadResult);

      // Test d'upload
      setProgress(0);
      const uploadResult = await testUploadSpeed();
      if (uploadResult === 0) {
        throw new Error('Test d\'upload échoué');
      }
      setUploadSpeed(uploadResult);
    } catch (error) {
      console.error('Erreur pendant les tests:', error);
    } finally {
      setTesting(false);
    }
  };

  const SpeedDisplay = ({ icon, label, value, unit }) => (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(45deg, ${theme.palette.primary.main}22, ${theme.palette.secondary.main}22)`,
      }}
    >
      {icon}
      <Typography variant="h6" component="div" sx={{ mt: 2 }}>
        {label}
      </Typography>
      <Typography variant="h4" component="div" sx={{ mt: 1 }}>
        {value !== null ? value.toFixed(1) : '-'}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {unit}
      </Typography>
    </Paper>
  );

  return (
    <Box sx={{ p: 4 }}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Test de Vitesse
      </Typography>

      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Button
          variant="contained"
          size="large"
          onClick={startTest}
          disabled={testing}
          startIcon={testing ? <CircularProgress size={20} /> : <Speed />}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 2,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          }}
        >
          {testing ? 'Test en cours...' : 'Démarrer le test'}
        </Button>
      </Box>

      {testing && (
        <Box sx={{ width: '100%', mb: 4 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
            {progress.toFixed(0)}%
          </Typography>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <SpeedDisplay
            icon={<CloudDownload sx={{ fontSize: 40, color: theme.palette.primary.main }} />}
            label="Téléchargement"
            value={downloadSpeed}
            unit="Mbps"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SpeedDisplay
            icon={<CloudUpload sx={{ fontSize: 40, color: theme.palette.secondary.main }} />}
            label="Upload"
            value={uploadSpeed}
            unit="Mbps"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SpeedDisplay
            icon={<Speed sx={{ fontSize: 40, color: theme.palette.error.main }} />}
            label="Latence"
            value={latency}
            unit="ms"
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          * Les résultats peuvent varier en fonction de votre localisation et du serveur de test
        </Typography>
      </Box>
    </Box>
  );
};

export default SpeedTest;
