import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Slider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
  Card,
  CardContent,
  CardActions,
  Button,
  Rating,
} from '@mui/material';
import { Wifi, Speed, Euro, Timer } from '@mui/icons-material';

const providers = [
  {
    name: 'Orange Fibre',
    speed: 2000,
    price: 42.99,
    commitment: 12,
    rating: 4.5,
    features: ['TV incluse', 'Appels illimités', 'Décodeur 4K'],
    technology: 'Fibre',
  },
  {
    name: 'SFR Box 8',
    speed: 1000,
    price: 32.99,
    commitment: 12,
    rating: 4.2,
    features: ['TV incluse', 'Appels illimités', 'WiFi 6'],
    technology: 'Fibre',
  },
  {
    name: 'Free Freebox Delta',
    speed: 8000,
    price: 39.99,
    commitment: 12,
    rating: 4.3,
    features: ['TV incluse', 'Appels illimités', 'Player Devialet'],
    technology: 'Fibre',
  },
  {
    name: 'Bouygues Bbox ultym',
    speed: 2000,
    price: 35.99,
    commitment: 12,
    rating: 4.1,
    features: ['TV incluse', 'Appels illimités', 'WiFi 6'],
    technology: 'Fibre',
  },
];

const Comparison = () => {
  const [filters, setFilters] = useState({
    maxPrice: 100,
    minSpeed: 0,
    technologies: {
      fibre: true,
      adsl: true,
    },
    features: {
      tv: false,
      phone: false,
      wifi6: false,
    },
  });

  const handlePriceChange = (event, newValue) => {
    setFilters({ ...filters, maxPrice: newValue });
  };

  const handleSpeedChange = (event, newValue) => {
    setFilters({ ...filters, minSpeed: newValue });
  };

  const handleTechnologyChange = (event) => {
    setFilters({
      ...filters,
      technologies: {
        ...filters.technologies,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleFeatureChange = (event) => {
    setFilters({
      ...filters,
      features: {
        ...filters.features,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const filteredProviders = providers.filter((provider) => {
    return (
      provider.price <= filters.maxPrice &&
      provider.speed >= filters.minSpeed &&
      ((provider.technology === 'Fibre' && filters.technologies.fibre) ||
        (provider.technology === 'ADSL' && filters.technologies.adsl))
    );
  });

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Comparateur d'Offres Internet
      </Typography>

      <Grid container spacing={4}>
        {/* Filtres */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Filtres
            </Typography>

            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Prix maximum (€/mois)</Typography>
              <Slider
                value={filters.maxPrice}
                onChange={handlePriceChange}
                min={20}
                max={100}
                valueLabelDisplay="auto"
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Débit minimum (Mb/s)</Typography>
              <Slider
                value={filters.minSpeed}
                onChange={handleSpeedChange}
                min={0}
                max={8000}
                step={100}
                valueLabelDisplay="auto"
              />
            </Box>

            <FormControl component="fieldset" sx={{ mb: 3 }}>
              <Typography gutterBottom>Technologies</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.technologies.fibre}
                      onChange={handleTechnologyChange}
                      name="fibre"
                    />
                  }
                  label="Fibre"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.technologies.adsl}
                      onChange={handleTechnologyChange}
                      name="adsl"
                    />
                  }
                  label="ADSL"
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset">
              <Typography gutterBottom>Options</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.features.tv}
                      onChange={handleFeatureChange}
                      name="tv"
                    />
                  }
                  label="TV incluse"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.features.phone}
                      onChange={handleFeatureChange}
                      name="phone"
                    />
                  }
                  label="Téléphone illimité"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.features.wifi6}
                      onChange={handleFeatureChange}
                      name="wifi6"
                    />
                  }
                  label="WiFi 6"
                />
              </FormGroup>
            </FormControl>
          </Paper>
        </Grid>

        {/* Résultats */}
        <Grid item xs={12} md={9}>
          <Grid container spacing={3}>
            {filteredProviders.map((provider, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {provider.name}
                    </Typography>
                    
                    <Box sx={{ mb: 2 }}>
                      <Rating value={provider.rating} precision={0.1} readOnly />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Speed sx={{ mr: 1 }} />
                      <Typography>
                        {provider.speed} Mb/s
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Euro sx={{ mr: 1 }} />
                      <Typography>
                        {provider.price}€/mois
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Timer sx={{ mr: 1 }} />
                      <Typography>
                        Engagement {provider.commitment} mois
                      </Typography>
                    </Box>

                    <Typography variant="subtitle2" gutterBottom>
                      Inclus :
                    </Typography>
                    <ul>
                      {provider.features.map((feature, idx) => (
                        <li key={idx}>{feature}</li>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      href={`/provider/${provider.name.toLowerCase().replace(' ', '-')}`}
                    >
                      Voir l'offre
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Comparison;
