import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Checkbox,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import { Speed, Group, Devices, EuroSymbol, Check } from '@mui/icons-material';

const steps = ['Usage', 'Utilisateurs', 'Appareils', 'Budget'];

const usageOptions = [
  { value: 'basic', label: 'Navigation web et emails', weight: 1 },
  { value: 'streaming', label: 'Streaming vidéo (Netflix, YouTube)', weight: 2 },
  { value: 'gaming', label: 'Jeux en ligne', weight: 3 },
  { value: 'work', label: 'Télétravail avec visioconférences', weight: 2 },
  { value: 'download', label: 'Téléchargements fréquents', weight: 3 },
];

const NeedsEvaluator = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    usage: [],
    users: 1,
    devices: 1,
    budget: 30,
  });
  const [recommendation, setRecommendation] = useState(null);

  const handleNext = () => {
    if (activeStep === 0 && formData.usage.length === 0) {
      alert('Veuillez sélectionner au moins un usage.');
      return;
    }
    
    if (activeStep === steps.length - 1) {
      calculateRecommendation();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleUsageChange = (event) => {
    const value = event.target.value;
    const newUsage = [...formData.usage];
    
    if (event.target.checked) {
      newUsage.push(value);
    } else {
      const index = newUsage.indexOf(value);
      if (index > -1) {
        newUsage.splice(index, 1);
      }
    }
    
    setFormData({ ...formData, usage: newUsage });
  };

  const calculateRecommendation = () => {
    // Calcul du poids total des usages
    const totalWeight = formData.usage.reduce((acc, usage) => {
      const option = usageOptions.find(opt => opt.value === usage);
      return acc + (option ? option.weight : 0);
    }, 0);

    // Calcul de la vitesse recommandée basée sur les usages, utilisateurs et appareils
    const baseSpeed = totalWeight * 25;
    const userFactor = Math.max(1, formData.users * 0.8);
    const deviceFactor = Math.max(1, Math.ceil(formData.devices / 2) * 0.7);
    
    const recommendedSpeed = Math.max(
      50,
      Math.round(baseSpeed * userFactor * deviceFactor)
    );

    // Détermination de la technologie
    const technology = recommendedSpeed > 100 ? 'Fibre' : 'ADSL/VDSL';
    
    // Calcul des prix estimés
    const basePrice = 25;
    const speedFactor = recommendedSpeed / 50;
    const estimatedPrice = Math.min(
      formData.budget,
      Math.round(basePrice + (speedFactor * 10))
    );

    // Génération des recommandations des fournisseurs
    const providers = [
      {
        name: 'Orange',
        price: Math.round(estimatedPrice * 0.9),
        speed: recommendedSpeed,
        features: ['Meilleure couverture', 'Service client premium', 'Box TV 4K'],
      },
      {
        name: 'SFR',
        price: estimatedPrice,
        speed: recommendedSpeed,
        features: ['Prix compétitif', 'Installation gratuite', 'Box gaming'],
      },
      {
        name: 'Free',
        price: Math.round(estimatedPrice * 0.8),
        speed: recommendedSpeed,
        features: ['Prix attractif', 'Freebox innovante', 'Sans engagement'],
      },
      {
        name: 'Bouygues',
        price: Math.round(estimatedPrice * 0.85),
        speed: recommendedSpeed,
        features: ['Bon rapport qualité/prix', 'WiFi 6', 'Bonus smartphone'],
      }
    ];

    // Ajout de recommandations spécifiques basées sur les usages
    const recommendations = [];
    if (formData.usage.includes('gaming')) {
      recommendations.push('Pour le gaming, privilégiez une connexion filaire et un FAI avec une faible latence.');
    }
    if (formData.usage.includes('streaming')) {
      recommendations.push('Pour le streaming 4K, une connexion d\'au moins 25 Mbps par utilisateur est recommandée.');
    }
    if (formData.usage.includes('work')) {
      recommendations.push('Pour le télétravail, pensez à sécuriser votre connexion avec un VPN.');
    }
    if (formData.devices > 5) {
      recommendations.push('Avec de nombreux appareils, un routeur WiFi 6 est conseillé pour une meilleure répartition.');
    }

    setTimeout(() => {
      setRecommendation({
        speed: recommendedSpeed,
        technology,
        estimatedPrice,
        providers,
        recommendations,
        usageDetails: {
          totalWeight,
          userFactor,
          deviceFactor,
          baseSpeed
        }
      });
      setActiveStep((prevStep) => prevStep + 1);
    }, 1500);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FormControl component="fieldset">
            <Typography gutterBottom>
              Sélectionnez vos usages principaux d'internet :
            </Typography>
            {usageOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={formData.usage.includes(option.value)}
                    onChange={handleUsageChange}
                    value={option.value}
                  />
                }
                label={option.label}
              />
            ))}
          </FormControl>
        );
      
      case 1:
        return (
          <Box>
            <Typography gutterBottom>
              Nombre d'utilisateurs simultanés :
            </Typography>
            <Slider
              value={formData.users}
              onChange={(e, newValue) => setFormData({ ...formData, users: newValue })}
              min={1}
              max={10}
              marks
              valueLabelDisplay="auto"
            />
          </Box>
        );
      
      case 2:
        return (
          <Box>
            <Typography gutterBottom>
              Nombre d'appareils connectés :
            </Typography>
            <Slider
              value={formData.devices}
              onChange={(e, newValue) => setFormData({ ...formData, devices: newValue })}
              min={1}
              max={20}
              marks
              valueLabelDisplay="auto"
            />
          </Box>
        );
      
      case 3:
        return (
          <Box>
            <Typography gutterBottom>
              Budget mensuel maximum (€) :
            </Typography>
            <Slider
              value={formData.budget}
              onChange={(e, newValue) => setFormData({ ...formData, budget: newValue })}
              min={20}
              max={100}
              marks
              valueLabelDisplay="auto"
            />
          </Box>
        );
      
      case 4:
        return recommendation ? (
          <Box>
            <Typography variant="h6" gutterBottom>
              Recommandations pour votre profil
            </Typography>
            
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>
                  Technologie recommandée : {recommendation.technology}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Débit conseillé : {recommendation.speed} Mb/s
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Budget estimé : {recommendation.estimatedPrice}€/mois
                </Typography>
              </CardContent>
            </Card>

            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Recommandations spécifiques
            </Typography>
            <List>
              {recommendation.recommendations.map((rec, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Check color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={rec} />
                </ListItem>
              ))}
            </List>

            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Offres recommandées
            </Typography>
            <Grid container spacing={2}>
              {recommendation.providers.map((provider) => (
                <Grid item xs={12} sm={6} key={provider.name}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" color="primary">
                        {provider.name}
                      </Typography>
                      <Typography variant="h4" gutterBottom>
                        {provider.price}€
                        <Typography component="span" variant="body2" color="text.secondary">
                          /mois
                        </Typography>
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Jusqu'à {provider.speed} Mb/s
                      </Typography>
                      <List dense>
                        {provider.features.map((feature, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <Check color="success" />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        );
      
      default:
        return null;
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Évaluez vos besoins en internet
      </Typography>
      
      <Stepper activeStep={activeStep} sx={{ py: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 3 }}>
        {renderStepContent(activeStep)}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        {activeStep > 0 && activeStep < steps.length && (
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Retour
          </Button>
        )}
        {activeStep < steps.length && (
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={activeStep === 0 && formData.usage.length === 0}
          >
            {activeStep === steps.length - 1 ? 'Obtenir les recommandations' : 'Suivant'}
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default NeedsEvaluator;
